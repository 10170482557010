.header {
  display: flex;
  align-items: center;
  padding: 32px 32px 24px;
  justify-content: space-between;
  gap: 20px;
}

.title-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.description-wrapper {
  padding: 0 24px 0 32px;
}

.create-button {
  margin-left: auto;
}

.create-button-icon {
  width: 30px;
  height: 30px;
} 

.backdrop {
  background: none;
}

.drawer-paper {
  width: 100%;
  max-width: 728px;
  box-shadow: none;
}

@media screen and (max-width: 1023px) {
  .drawer-paper {
    overflow-x: hidden;
    width: 100vw;
    height: 100%;
  }

  .header {
    padding: 32px 32px 0;
    align-items: start;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }

  .create-button {
    margin-left: 0;
  }
}
