.emptyState {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.emptyIcon {
  display: block;
  margin: 0 auto;
  width: 160px;
  height: 160px;
  color: var(--main-grey-color)
}

.listContainer {
  height: 100%;
}

.list {
  height: 100vh;
  box-shadow: var(--main-grey-color) 0px 5px 14px -14px inset;
}