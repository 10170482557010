.checkbox {
  padding: 0;
}

.checkbox:hover {
  background: none;
}

.checkbox-disabled path:first-of-type {
  fill: var(--background-disabled-color);
}

.indeterminate::after {
  content: '';
  display: block;
  width: 8px;
  height: 2px;
  background: var(--link-color);
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
