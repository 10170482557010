.drawer-paper {
  width: 100%;
  max-width: 400px;
  height: 100vh;
}

.wrapper {
  background: var(--main-background-color);
  padding: 27px 24px 0 24px;
  width: 100%;
  max-width: 400px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.close-button path {
  stroke: var(--main-color);
}

.user-icon-wrapper {
  margin-bottom: 16px;
  border-radius: 50%;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  background: var(--main-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--button-background-color);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.email {
  margin-bottom: 24px;
}

.panel {
  margin-bottom: 24px;
  padding: 0 0 16px 24px;
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
}

.panel-title {
  padding: 16px 0px;
  display: flex;
  align-items: center;
}

.info-item-value:not(:last-child) {
  margin-bottom: 8px;
}
