@font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 400;
  src: local('Play'), local('Play-Regular'), url('../fonts/Play-Regular.ttf') format('truetype');
}

.top-bar {
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid #e7e8ec;
  text-align: start;
}

.tooltip {
  background: #0b1641;
}

.tooltip-arrow {
  color: #0b1641;
}

.top-bar-button {
  border: none;
  background: none;
  margin-right: 18px;
  cursor: pointer;
  padding: 4px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-icon {
  margin-right: 12px;
  max-width: 28px;
  max-height: 28px;
}

.logo-text {
  color: #aaadb3;
  font-size: 14px;
  letter-spacing: 0em;
  margin-left: 20px;
  position: relative;
}

.logo-text::after {
  position: absolute;
  content: '';
  left: -10px;
  width: 1px;
  height: 16px;
  background: #aaadb3;
  top: 50%;
  transform: translateY(-50%);
}

.section-name {
  font-family: Play;
  color: #333;
  font-size: 20px;
  letter-spacing: 0em;
}

.user-name {
  font-family: 'Inter';
  font-size: 14px;
  margin-left: 16px;
}

.user-icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: #ecedf0;
  border: 1px solid #e7e8ec;
  border-radius: 50%;
  object-fit: cover;
}

.exit-button {
  margin-left: 20px;
  border: none;
  padding: 10px 24px;
  cursor: pointer;
}

.menu {
  border-top: 1px solid #e7e8ec;
}

.menu-min-height {
  min-height: 280px;
}

.menu-button-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px;
  padding-left: 20px;
  border-bottom: 1px solid #e7e8ec;
}

.menu-button-wrapper:hover {
  background: #f5f6f8;
}

.menu-button {
  border: none;
  background: none;
  padding: 0;
  margin-left: 16px;
  cursor: pointer;
  font-family: 'Inter';
}

.bottom {
  margin-top: auto;
}

.bell-icon {
  margin-left: auto;
}

.help-button-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.help-button {
  font-size: 14px;
  color: #797a80;
  background: none;
  border: none;
  font-family: 'Inter';
  cursor: pointer;
  margin-left: 8px;
}

.version,
.copyright {
  color: #797a80;
  font-family: 'Inter';
  margin-top: 8px;
}

.version {
  font-size: 14px;
}

.copyright {
  font-size: 12px;
}

.menu-icon:hover,
.bell-icon:hover {
  background: #f5f6f8 !important;
  border-radius: 0;
}

.start-icon {
  margin-right: 8px;
}

.pointer {
  cursor: pointer;
}

.account {
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.accounts-menu-item {
  display: flex;
  align-items: center;
  background: #f5f6f8;
  border-radius: 14px;
}

.accounts-menu-paper {
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.accounts-menu-header {
  min-width: 200px;
  text-align: center;
}

.user-image-wrapper {
  text-align: center;
}

.user-image {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  background: #ecedf0;
  border: 1px solid #e7e8ec;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}

.user-text {
  text-align: center;
  margin: 0;
}

.user-exit {
  width: 100%;
  text-align: center;
  background: #f5f6f8;
  border-radius: 14px;
  margin-top: 10px;
}

.user-exit-icon {
  margin-left: 8px;
}

.modal-paper {
  box-shadow: none;
  width: 300px;
  top: 63px;
  border-top: 1px solid #e7e8ec;
  border-right: 1px solid #e7e8ec;
}

.menu-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 20px;
}

@media screen and (max-width: 660px) {
  .modal-paper {
    width: 100%;
  }

  .user-name,
  .exit-button {
    display: none;
  }
}

@media screen and (max-width: 440px) {
  .top-bar {
    padding: 0 12px;
  }

  .top-bar-button {
    margin-right: 10px;
  }

  .logo-text {
    display: none;
  }
}
