.footer-wrapper {
  width: 100%;
  display: grid;
  place-content: center;
  background-color: var(--main-background-color);
  border-top: 1px solid var(--button-background-color);
}

.footer {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 32px;
}

.link {
  color: var(--link-color); /* Наследуем цвет текста */
  text-decoration: none; /* Убираем подчеркивание */
  cursor: pointer; /* Указываем, что элемент кликабельный */
}

@media screen and (max-width: 1023px) {
  .footer-wrapper {
    place-content: initial;
  }

  .footer {
    gap: 8px;
    padding: 8px 32px;
    justify-content: space-between;
  }
}

@media screen and (max-width: 440px) {
  .footer-wrapper {
    display: none;
  }
}
