.lock-button {
  display: flex;
  justify-content: center;
  width: 64px;
  height: 40px;
  border-radius: 4px;
  margin-right: 16px;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 24px;
}

.lock-button-disabled {
  margin-right: 16px;
  margin-left: auto;
  padding: 0 16px;
}

.arrow-down {
  display: none;
  right: -8px;
  position: absolute;
}

.lock-button:hover {
  background: var(--selected-background-color) !important;
}

.lock-button:hover .arrow-down {
  display: block;
}

.no-hover {
  pointer-events: none;
}

.paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  border-radius: 1px;
  padding: 8px 0;
  width: 260px;
  background: var(--main-background-color);
  transform: translateX(40px) translateY(12px) !important;
}

.popover-button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 24px;
  text-align: start;
}

.selected-popover-button {
  background: var(--selected-background-color);
}

.popover-info {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  text-align: left;
  max-width: 180px;
}

.button {
  margin-left: 0;
}

@media screen and (max-width: 1023px) {
  .content {
    border: none;
    width: auto;
    padding: 16px;
  }
}

@media screen and (max-width: 767px) {
  .lock-button {
    margin-right: 0;
  }

  .lock-button-disabled {
    margin-right: 0;
  }
}
