.drawer-paper {
  width: 100%;
  max-width: 728px;
}

.wrapper {
  background: var(--main-background-color);
  padding: 27px 32px 0 32px;
  width: 100%;
  max-width: 728px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.description {
  margin-top: 4px;
  font-size: 12px;
  color: #858ba0;
}
