.header {
  display: flex;
  gap: 20px;
}

.headerContent {
  display: flex;
  width: 100%;
  max-width: 500px;
  gap: 20px;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid var(--button-background-color);
}

.headerAvatar {
  width: 120px;
  height: 120px;
  border: 1px solid var(--button-background-color);
}

.avatar {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.headerInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerRole, .headerRoleWrapper {
  display: flex;
  align-items: center;
}

.iconWrapper {
  width: 24px;
  height: 24px;
  margin-right: 6px;
  & svg {
    width: 18px;
    height: 18px;
  }
}

.roleText {
  margin-right: 6px;
}

.headerButtons {
  display: flex;
  gap: 10px;
}

.buttonIcon {
  width: 24px;
  height: 24px;
}

.descriptionContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid var(--button-background-color);
}

.descriptionHeader {
  display: flex;
  align-items: center;
}

.descriptionText {
  word-break: break-word;
}

@media screen and (max-width: 767px) { 
  .header {
    display: block;
  } 
  
  .headerContent {
    max-width: unset;
  }

  .descriptionContent {
    margin-top: 20px;
  }
}

@media screen and (max-width: 440px) { 
  .headerRoleWrapper {
    display: block;
  } 
}

