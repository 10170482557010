.modal-container {
  background: var(--main-background-color);
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
}

.avatar {
  background-color: #f89f33;
  width: 40px;
  height: 40px;
  margin-right: 24px;
}

.avatar svg {
  height: 32px;
  width: 32px;
}

.avatar ellipse,
.avatar path {
  stroke: var(--main-background-color);
}

.radio-delete-users {
  margin-bottom: 10px;
}

.radio-delete-apps {
  margin-bottom: 24px;
}

.apps-to-delete {
  min-height: 67px;
  padding-right: 5px;
  max-height: calc(100vh - 570px);
  overflow-y: auto;
}

.app-to-delete {
  display: flex;
  align-items: center;
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
  padding: 12px 16px;
}

.app-to-delete + .app-to-delete {
  margin-top: 16px;
}

.app-icon {
  background: var(--main-background-color);
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 32px;
}

.app-link {
  max-width: calc(100% - 90px);
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: var(--link-color);
}

.app-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1023px) {
  .modal-container {
    width: 90vw;
  }
}

.drawer-paper {
  top: 64px;
  border: none;
  box-shadow: none;
  width: 100%;
  max-width: 400px;
}
