.button-back {
  padding: 0;
}

.wrapper .button-back:hover {
  background: none;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.change-password {
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.password-textfield {
  margin-bottom: 24px;
}

@media screen and (max-width: 1023px) {
  .wrapper {
    padding: 33px 16px;
    width: 100%;
  }

  .change-password {
    width: auto;
  }
}
