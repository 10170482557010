.chips-wrapper {
  box-sizing: border-box;
  border: 1px solid var(--button-background-color);
  border-radius: 1px;
  font-family: Inter;
  padding: 1px 1px 1px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.chips-input:focus-within {
  padding: 0 0 0 15px;
  border-width: 2px;
}

.chips-input-error:focus-within {
  padding: 0 0 0 15px;
  border-width: 2px;
}

.scopes-input {
  flex-grow: 1;
}

.scopes-input input {
  padding: 0;
  height: 40px;
}

.scopes-input fieldset {
  display: none;
}

.scopes-input-error {
  margin-top: 8px;
  font-family: Inter;
}

.chip {
  background: var(--background-disabled-color);
  height: 32px;
  padding: 0 10px 0 12px;
  border-radius: 30px;
  margin: 4px 8px 4px 0;
  border: none;
  color: #000;
}

.chip svg {
  width: 18px;
  height: 18px;
}

.chip span {
  padding: 0;
  margin-right: 10px;
}
