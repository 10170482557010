.card {
  padding: 24px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: var(--main-background-color);
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.1),
    0px 3px 7px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
}

.card:hover {
  background: var(--selected-background-color) !important;
}

.avatar-wrapper {
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.avatar-wrapper-notification {
  background: var(--hover-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 32px;
  height: 32px;
}

.message-wrapper {
  margin-right: 46px;
  width: 100%;
  max-width: 232px;
  height: 63px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.messages {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.one-notice {
  -webkit-line-clamp: 3;
}

.with-counter {
  -webkit-line-clamp: 2;
}

.close-button {
  padding: 0;
}

.close-button path {
  stroke: var(--main-color);
}
