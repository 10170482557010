.change-permission-modal {
  background: var(--main-background-color);
  padding: 24px 24px 32px 32px;
  border-radius: 6px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.avatar {
  width: 40px;
  height: 40px;
  border: 1px solid #ced0d9;
  background-color: #f89f33;
  margin-right: 12px;
}

.avatar svg {
  height: 32px;
  width: 32px;
}

.avatar ellipse,
.avatar path {
  stroke: var(--main-background-color);
}
