.drawer-paper {
  width: 100%;
  max-width: 728px;
}

.wrapper {
  background: var(--main-background-color);
  padding: 27px 32px 0 32px;
  width: 100%;
  max-width: 728px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.create-button {
  margin-left: auto;
}

.create-button path {
  stroke: var(--main-color);
}

.close-button {
  margin-left: 32px;
  padding: 0;
}

.close-button path {
  stroke: var(--main-color);
}

.input-description {
  margin-bottom: 16px;
}

.search {
  margin-bottom: 31px;
}

.search-icon {
  position: absolute;
  left: 12px;
}

.input {
  padding-left: 48px !important;
}

.filter {
  margin-bottom: 15px;
}

.filter-text {
  margin-left: 8px;
  margin-right: 4px;
}

.popover-button {
  width: 100%;
  justify-content: flex-start;
  height: 48px;
  padding-left: 24px;
}

.popover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: var(--main-background-color);
}

.popover-button-icon {
  margin-right: 16px;
}

.hidden-button-icon {
  visibility: hidden;
  opacity: 0;
}

.provider {
  display: flex;
  background: var(--main-background-color);
  align-items: center;
  height: 64px;
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  margin-right: 8px;
  padding: 0 32px 0 18px;
}

.provider + .provider {
  margin-top: 16px;
}

.checked-provider {
  background: var(--selected-background-color);
}

.provider-checkbox {
  flex: 0 0 auto;
}

.provider-icon-wrapper {
  border-radius: 8px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: var(--hover-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin: 0 24px 0 19px;
  border: 1px solid var(--button-background-color);
  background-size: cover;
  background-repeat: no-repeat;
}

.provider-name-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
  margin-right: 24px;
}

.provider-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.provider-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex: 0 0 auto;
  margin-left: 8px !important;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--button-background-color);
  margin-top: auto;
}

.delete-modal {
  background: var(--main-background-color);
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.emptyIcon {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 1023px) {
  .wrapper {
    padding: 27px 16px 0 16px;
  }
}

@media screen and (max-width: 767px) {
  .types-wrapper {
    padding-left: 10px;
  }

  .provider {
    margin-right: 8px;
    padding: 0 16px 0 9px;
  }

  .provider-icon-wrapper {
    margin: 0 12px 0 8px;
  }

  .provider-name-wrapper {
    margin-right: 12px;
  }
}
