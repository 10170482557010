.drawer-paper {
  width: 100%;
  max-width: 728px;
}

.wrapper {
  background: var(--main-background-color);
  padding: 27px 32px 0 32px;
  width: 100%;
  max-width: 728px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  justify-content: space-between;
}

.close-button {
  margin-left: 32px;
  padding: 0;
}

.submit-buttons {
  padding: 24px 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 88px;
  border-top: 1px solid var(--button-background-color);
}
