.padding-wrapper {
  padding: 32px;
}

.subtitle {
  margin-bottom: 24px;
}

.input-title {
  margin-bottom: 8px;
}

.textfield {
  margin-bottom: 24px;
}

.select {
  width: 100% !important;
}

.input-error {
  margin-bottom: 8px;
}

.submit-buttons {
  margin-left: auto;
  width: fit-content;
  margin-top: 32px;
}

.create-button {
  margin-left: 16px;
}

.save-modal {
  background: var(--main-background-color);
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.providers-with-avatars-modal {
  max-height: 80%;
  background-color: var(--main-background-color);
  max-width: 80%;
  padding: 32px;
}

.providers-wrapper {
  overflow: auto;
  height: 320px;
}

.account-name-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
  margin-right: 24px;
}

.account-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-icon {
  position: absolute;
  left: 12px;
}

.search {
  margin-bottom: 31px;
}

.input {
  padding-left: 48px !important;
}

.account-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 40px;
  background: var(--hover-background-color);
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
}

.account-icon-wrapper {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 24px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-description {
  margin-bottom: 16px;
}

.provider {
  display: flex;
  background: var(--main-background-color);
  align-items: center;
  height: 64px;
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  margin-right: 8px;
  padding: 0 32px 0 18px;
  cursor: pointer;
}

.provider:hover {
  background: var(--selected-background-color);
}

.provider + .provider {
  margin-top: 16px;
}

.checked-provider {
  background: var(--selected-background-color);
}

.custom-fields {
  margin-top: 24px;
}

.required-indicator {
  color: red;
  margin-left: 4px;
}
