.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.title {
  margin-top: 32px;
  margin-left: 32px;
}
.subtitle {
  margin-bottom: 24px;
}

.button-back {
  padding: 0;
}

.wrapper {
  padding: 32px 32px 0;
  padding-bottom: 20px;
  height: fit-content;
}

.wrapper .button-back:hover {
  background: none;
}

.input {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid var(--button-background-color);
  border-radius: 1px;
  font-family: Inter;
  color: #3c4567;
  padding-left: 16px;
}

.input-subtitle {
  margin-bottom: 32px;
}

.input-title {
  margin-bottom: 8px;
}

.input-root textarea {
  box-sizing: border-box;
  height: 56px !important;
  border: 1px solid var(--button-background-color);
  border-radius: 1px;
  outline: none;
  resize: none;
  font-family: Inter;
  color: #3c4567;
  padding-left: 16px;
}

.input:hover,
.input-root textarea:hover {
  border-color: #858ba0;
}

.input:focus,
.input-root textarea:focus {
  border-color: #606783;
  padding-left: 15px;
  border-width: 2px;
}

.create-client-form {
  margin-top: 32px;
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
}

.padding-wrapper {
  padding: 32px;
}

/* #427
.divider {
  width: 100%;
  height: 1px;
  background: var(--button-background-color);
}
*/

.submit-buttons {
  margin-left: auto;
  width: fit-content;
  margin-top: 32px;
}

.create-button {
  margin-left: 16px;
}

/* #427
.switch-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
} */

.input-error {
  margin-bottom: 8px;
}

.menu {
  width: 157px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  border-radius: 0;
}

.menu ul {
  padding: 0;
  border-radius: 0;
}

.select {
  height: 40px;
  border-radius: 0;
  width: 100%;
}

.select + .select {
  margin-left: 32px;
}

.select svg {
  top: calc(50% - 12px);
  right: 12px;
}

.select fieldset {
  border-color: var(--button-background-color);
}

.select-item {
  padding-left: 55px;
  display: block;
  position: relative;
}

.check-icon {
  display: none;
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.select-item-selected {
  background-color: var(--selected-background-color) !important;
}

.select-item-selected .check-icon {
  display: block;
}

.outlined {
  padding: 10px 14px;
}

.textfield {
  margin-bottom: 24px;
}

.save-modal {
  background: var(--main-background-color);
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  border: none;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1023px) {
  .wrapper {
    width: 100%;
    padding: 33px 16px 0;
  }

  .padding-wrapper {
    padding: 25px 0;
  }

  .create-client-form {
    margin-top: 0;
    height: calc(100vh - 124px);
    border: none;
    width: 100%;
  }

  .title {
    margin-bottom: 24px;
  }
}
