.content {
  width: 100%;
  display: flex;
  align-items: center;
  container-type: inline-size;
}

.clientInfo {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-areas:"mainInfo addInfo providers";
  grid-template-columns: minmax(100px, 200px) minmax(160px, 260px) minmax(170px, 300px);
}

.clientMainInfo {
  grid-area: mainInfo;
  margin: auto 0;
}

.clientAddInfo {
  grid-area: addInfo;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  margin: auto 0;
}

.rowWithIcon {
  display: flex;
  align-items: center;
}

.iconWrapper {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.icon {
  width: 20px;
  height: 20px;
}

.clientLink {
  display: inline;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.1s ease-in-out;
}

.clientLink:hover {
  cursor: pointer;
  color: var(--accent-color);
  text-decoration: underline;
}

.textNumber {
  margin-left: 4px;
  color: var(--main-color);
}

.clientProviders {
  grid-area: providers;
  margin: auto 0;
}

.providersList {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.providerButton {
  cursor: auto;
  background-color: var(--hover-background-color);
}

.providerAvatar {
  width: 24px;
  height: 24px;
}

.providersEmpty {
  margin-top: 10px;
}

.hideText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@container (max-width: 576px) {
  .card {
    overflow: hidden;
  }

  .clientInfo {
    grid-gap: 20px 10px;
    grid-template-areas:
    "mainInfo providers"
    "addInfo addInfo";
    grid-template-columns: 200px 1fr;
  }

  .clientAddInfo {
    justify-content: flex-start;
    flex-direction: row;
  }
}

@container (max-width: 408px) {
  .clientInfo {
    grid-gap: 20px 10px;
    grid-template-areas:
    "mainInfo addInfo"
    "providers providers";
    grid-template-columns: 0.4fr 0.6fr;
  }

  .clientAddInfo {
    overflow: hidden;
    flex-direction: column;
  }

  .clientMainInfo {
    display: grid;
  }
}

@container (max-width: 268px) {
  .clientInfo {
    display: block;
  }

  .clientAddInfo {
    gap: 6px;
    margin-top: 10px;
  }

  .iconWrapper {
    width: 26px;
    height: 26px;
    margin-right: 6px;
  }
  
  .icon {
    width: 18px;
    height: 18px;
  }

  .clientProviders {
    margin-top: 10px;
  }

  .providerButton {
    padding: 4px;
  }

  .hideText {
    -webkit-line-clamp: 1;
  }
}