.app-icon {
  background: var(--main-background-color);
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 32px;
}

.remove-input {
  display: none;
}

.label-button:active {
  pointer-events: none;
}

.label {
  cursor: pointer;
  display: block;
  width: fit-content;
}

.flex-container {
  display: flex;
  align-items: center;
}

.container {
  margin-bottom: 16px;
}

.delete-button,
.load-button {
  margin-right: 32px;
}

.avatar {
  border: 1px solid #ced0d9;
  border-radius: 50%;
}

.avatar svg {
  height: 32px;
  width: 32px;
}

.avatar ellipse,
.avatar path {
  stroke: var(--main-background-color);
}

.gray-avatar {
  background-color: #bdbdbd;
}
