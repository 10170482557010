.paper {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transform: translateX(40px) translateY(12px) !important;
  border-radius: 1px;
  background: var(--main-background-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
}

.actions-icon {
  width: 39px;
  height: 39px;
  border-radius: 0;
  padding: 0;
}

.actions-icon:hover,
.active-actions-icon {
  background: var(--selected-background-color) !important;
}

.actions-icon:hover path,
.active-actions-icon path {
  fill: rgba(0, 0, 0, 0.8);
}
