.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.change-email {
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.textfield {
  margin-bottom: 8px;
}

.button-back {
  padding: 0;
}

.wrapper .button-back:hover {
  background: none;
}
