.rule-item {
  margin-top: 16px;
  display: flex;
  align-items: center;
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  padding: 12px 20px 12px 24px;
}

.rule-item:hover {
  background: var(--background-darkened-color);
}

.rule-head {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rule-icons {
  display: flex;
  align-items: center;
  fill: #858ba0;
  height: 20px;
}

.rule-icon {
  margin-right: 20px;
}

.rule-title {
  font-size: 14px;
  color: var(--main-color);
  margin-bottom: 4px;
}

.rule-field {
  font-size: 12px;
  color: #858ba0;
}

.rule-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.rule-item.inactive {
  color: #c0c0c0;
  background-color: #f0f0f0;
  border-color: #d0d0d0;
}
