.drawer-paper {
  box-shadow: none;
}

.wrapper {
  background: var(--main-background-color);
  padding: 27px 0 0 0;
  max-width: 728px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 0 32px;
}

.button-back {
  padding: 0;
  margin-right: 16px;
}

.arrow-icon {
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
}

.arrow-icon path {
  fill: var(--link-color);
}

.title {
  flex: 1 1 auto;
}

.close-button {
  flex: 0 0 auto;
  padding: 0;
}

.close-button path {
  stroke: var(--main-color);
}

.provider-form {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 32px;
}

.asterisk::after {
  content: '*';
  color: red;
}

.input-title {
  margin-bottom: 8px;
}

.input-subtitle {
  margin-bottom: 32px;
  margin-top: 8px;
}

.switch-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottom-buttons {
  display: flex;
  align-items: center;
  justify-content: right;
}

.save-modal {
  background: var(--main-background-color);
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
