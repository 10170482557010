.addInfoContent {
  height: calc(100vh - 410px);
  overflow: auto;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid var(--button-background-color);
}

.addInfoItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.link {
  display: inline;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.1s ease-in-out;
}

.link:hover {
  cursor: pointer;
  color: var(--accent-color);
  text-decoration: underline;
}

.buttonCopy {
  padding: 4px;
  margin-left: 6px;
}

.iconCopy {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--button-background-color);
  margin-bottom: 20px;
}

.providers {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.providerButton {
  background-color: var(--hover-background-color);
}

.providerAvatar {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 930px) {  
  .addInfoContent {
    height: calc(100vh - 420px);
  }
}

@media screen and (max-width: 850px) {  
  .addInfoContent {
    height: calc(100vh - 440px);
  }
}

@media screen and (max-width: 767px) { 
  .addInfoContent {
    height: 100%;
  }
}


