.item-name-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
  margin-right: 24px;
}

.item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
  margin-right: 8px;
}

.item-name-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.item-wrapper {
  display: flex;
  justify-content: space-between;
  background: var(--main-background-color);
  align-items: center;
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  margin-right: 8px;
  padding: 12px 20px 12px 24px;
  width: 100%;
}

.chip-wrapper {
  border-radius: 30px;
  padding: 1px 8px;
  margin-bottom: 4px;
}

.active-bg {
  background: var(--accent-color);
}

.not-active-bg {
  background: var(--button-background-color);
}

.chips-container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1023px) {
  .item-name-container {
    flex-direction: column;
    align-items: start;
    gap: 2px;
    margin-bottom: 12px;
  }

  .item-wrapper {
    display: flex;
    align-items: start;
  }
}
