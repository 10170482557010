@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.load-icon {
  animation: loading 2s linear infinite;
  position: absolute;
}

.load-icon.has-start-icon {
  left: 50%;
}

.button::after {
  content: attr(title);
  opacity: 0;
  visibility: hidden;
}

.children {
  position: absolute;
}

.has-start-icon {
  left: 48px;
}
