.content {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
}

.mainInfo {
  width: 40%;
}

.title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.addInfo {
  width: 60%;
}

.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 767px) {
  .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

@media screen and (max-width: 599px) {
  .mainInfo {
    width: 100%;
  }
  
  .addInfo {
    display: none;
  }
}
