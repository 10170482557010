.drawer-paper {
  width: 100%;
  max-width: 728px;
}

.close-button {
  flex: 0 0 auto;
  padding: 0;
}

.close-button path {
  stroke: var(--main-color);
}

.wrapper {
  background: var(--main-background-color);
  padding: 28px 32px;
  width: 100%;
  max-width: 728px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.asterisk::after {
  content: '*';
  color: red;
}

.margin-bottom {
  margin-bottom: 8px;
}

.input-subtitle {
  margin-bottom: 32px;
  margin-top: 8px;
}

.switcher {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch {
  margin-right: 32px;
}

.info-item-value {
  min-width: 105px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bottom-buttons {
  padding: 24px 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 88px;
  border-top: 1px solid var(--button-background-color);
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.provider {
  display: flex;
  align-items: center;
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  padding: 12px 20px 12px 24px;
}

.provider-icon-wrapper {
  border-radius: 8px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: var(--hover-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-right: 24px;
  border: 1px solid var(--button-background-color);
  background-size: cover;
  background-repeat: no-repeat;
}

.provider-name-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
  margin-right: 24px;
}

.provider-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
