.profile-item {
  margin-top: 16px;
  display: flex;
  align-items: center;
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  padding: 12px 20px 12px 24px;
}

.profile-item:hover {
  background: var(--background-darkened-color);
}

.profile-head {
  display: flex;
  flex-direction: column;
}

.profile-icons {
  display: flex;
  align-items: center;
  fill: #858ba0;
  height: 20px;
}

.profile-title {
  font-size: 14px;
  margin-bottom: 4px;
}

.profile-field {
  font-size: 12px;
}

.profile-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.inactive {
  color: #c0c0c0;
  background-color: #f0f0f0;
  border-color: #d0d0d0;
}
