.card {
  display: flex;
  padding: 0 24px;
  border: 1px solid var(--button-background-color);
  border-radius: 8px;
}

.cardAction:hover {
  cursor: pointer;
  background: var(--background-darkened-color);
}

.cardImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-right: 20px;
  border-radius: 10px;
  background: var(--main-background-color);
  border: 1px solid var(--button-background-color);
  flex-shrink: 0;
}

.avatar {
  width: 100%;
  height: 100%;
}

.cardImageWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 425px) {
  .card {
    padding: 0 10px;
  }
}