.wrapper {
  padding: 24px 24px 24px 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  min-height: 135px;
}

.wrapper:hover {
  background: var(--selected-background-color) !important;
}

.inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 87px;
}

.icons {
  margin-right: 16px;
  display: flex;
  align-items: center;
  width: 54px;
}

.circle-wrapper {
  width: 14px;
}

.circle {
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background: var(--link-color);
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.message-wrapper {
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 256px;
  height: 87px;
}

.message {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-wrapper {
  min-width: 40px;
  height: 40px;
}

.close-button_show {
  display: none;
  padding-top: 0;
}

.wrapper:hover .close-button_show {
  display: block;
}
