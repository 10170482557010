.drawer-paper {
  width: 100%;
  max-width: 728px;
}

.close-button path {
  stroke: var(--main-color);
}

.add-button {
  margin-top: 8px;
}

.wrapper {
  background: var(--main-background-color);
  padding: 27px 32px 0 32px;
  width: 100%;
  max-width: 728px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.header2 {
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 32px 0px 16px 0px;
}

.header3 {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 16px 0px 4px 0px;
}

.switchers {
  margin-top: 32px;
}

.switcher {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch {
  margin-right: 32px;
}

.info-item-value {
  min-width: 105px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input {
  border: 1px solid var(--button-background-color);
  border-radius: 1px;
  min-height: 42px;
  width: 100%;
  color: #3c4567;
  padding: 1px 1px 1px 16px;
}

.description {
  margin-top: 4px;
}

.bottom-buttons {
  padding: 24px 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 88px;
  border-top: 1px solid var(--button-background-color);
}
