.drawer-paper {
  top: 64px;
  border: none;
  box-shadow: none;
  width: 100%;
  max-width: 400px;
}

.wrapper {
  height: calc(100vh - 118px);
  border-left: 1px solid var(--button-background-color);
  width: 100%;
  max-width: 400px;
}

.bar {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--button-background-color);
}

.section-count {
  display: flex;
}

.margin-right {
  margin-right: 8px;
}

.section-buttons {
  display: flex;
  flex-wrap: nowrap;
}

.tooltip {
  background: var(--main-color);
  color: var(--main-background-color);
}

.tooltip-arrow {
  color: var(--main-color);
}

.close-button path {
  stroke: var(--main-color);
}

.close-button:hover,
.button:hover {
  background: var(--selected-background-color) !important;
  border-radius: 0;
}

.notices {
  height: calc(100vh - 128px);
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  scrollbar-width: thin;
}
