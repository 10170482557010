.applications {
  height: auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--button-background-color);
}

.tabs .tab-indicator {
  background: var(--main-color);
  height: 1px;
}

.tabs {
  padding: 0 24px;
}

.tabs .tab {
  padding: 0;
  min-width: 0;
  width: fit-content;
  height: 54px;
  text-transform: none;
}

.tabs .tab-selected {
  color: var(--main-color);
}

@media screen and (max-height: 500px) {
  .applications {
    overflow: scroll;
  }
}

@media screen and (max-width: 440px) {
  .applications {
    border-top: 1px solid var(--button-background-color);
  }

  .tabs {
    width: 100%;
    padding: 0 10px;
  }

  .tabsContainer {
    width: 100%;
    justify-content: space-between;
  }
}
