.paper {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1px;
  padding: 8px 0;
  width: 290px;
  background: var(--main-background-color);
}

.divider {
  background: var(--button-background-color);
  height: 1px;
  margin: 8px 0;
  width: 100%;
}

.buttonWrapper {
  width: 100%;
}

.buttonIcon {
  margin-right: 16px;
}