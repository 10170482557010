.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.title {
  margin-bottom: 32px;
}

.subtitle {
  margin-bottom: 24px;
}

.button-back {
  padding: 0;
}

.wrapper {
  padding: 32px 32px 0;
  padding-bottom: 20px;
}

.wrapper .button-back:hover {
  background: none;
}

.field-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.input-subtitle {
  margin-bottom: 32px;
  margin-top: 8px;
}

.add-text-field {
  flex-grow: 1;
  margin-right: 30px;
}

.input-title {
  margin-bottom: 8px;
}

.input-error {
  margin-bottom: 8px;
}

.add-button,
.delete-button {
  white-space: nowrap;
  flex-shrink: 0;
}

.add-button {
  margin-top: 8px;
}

.asterisk::after {
  content: '*';
  color: red;
}

.add-button-bottom {
  margin-top: 0;
  margin-bottom: 20px;
}

.create-client-form {
  margin-top: 32px;
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
}

.padding-wrapper {
  padding: 32px;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--button-background-color);
}

.submit-buttons {
  margin-left: auto;
  width: fit-content;
  margin-top: 24px;
}

.create-button {
  margin-left: 16px;
}

.switch-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
}

.token-input {
  position: relative;
}

.token-textfield {
  margin-bottom: 32px;
}

.token-input::after {
  content: 'секунд';
  display: block;
  left: auto;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  right: 32px;
  margin: 0;
  color: #b6bac6;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 21px;
}

.save-modal {
  background: var(--main-background-color);
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.typeButton {
  flex-shrink: 0;
  height: 36.5px;
}

.type-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 32px;
}

@media screen and (max-width: 1023px) {
  .wrapper {
    width: 100%;
    padding: 33px 16px 0;
  }

  .padding-wrapper {
    padding: 25px 0;
  }

  .create-client-form {
    margin-top: 0;
    border: none;
    width: 100%;
  }

  .title {
    margin-bottom: 24px;
  }
}
