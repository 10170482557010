.client {
  height: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
  "mainInfo mainInfo"
  "usersList addInfo";
  grid-template-columns: 0.6fr 0.4fr;
  grid-template-rows: max-content;
  padding: 20px;
}

.mainInfo {
  grid-area: mainInfo;
}


.addInfo {
  grid-area: addInfo;
}

.usersList {
  grid-area: usersList;
}

@media screen and (max-width: 1023px) {  
  .client {
    grid-template-areas:
    "mainInfo mainInfo"
    "usersList addInfo";
    grid-template-columns: 1fr 1fr;
  }  
}

@media screen and (max-width: 767px) { 
  .client {
    overflow-y: auto;
    display: block;
  }  

  .addInfo {
    margin: 20px 0;
  }
}


