.info {
  max-width: 100%;
}

.chipContainer {
  display: flex;
  gap: 8px;
  margin-top: 10px;
}

.chip {
  height: 24px;
  color: var(--main-grey-color);
}

.infoTitle {
  margin: 8px 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.infoRow {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.infoText {
  color: var(--main-grey-color);
}

@media screen and (max-width: 425px) {
  .title {
    margin: 4px 0;
  }
}
