.info-item {
  display: flex;
  align-items: center;
  min-height: 64px;
  max-height: 128px;
}

.info-item + .info-item {
  border-top: 1px solid var(--button-background-color);
}

.provider-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 40px;
  background: var(--hover-background-color);
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
}

.provider-icon-wrapper {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
}

.provider-info {
  margin-left: 24px;
  overflow: hidden;
}

.ellipsis {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.popover-button {
  padding: 8px 24px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  text-align: start;
}

@media screen and (max-width: 767px) {
  .provider-info {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
  }
}
