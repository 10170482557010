.eventBlock {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100% - 40px);
  opacity: 1;
  transition: all ease 0.3s;
}

.eventName {
  margin-bottom: 32px;
  word-wrap: break-word;
}

.eventTitle {
  margin-bottom: 10px;
}

.eventText {
  margin-bottom: 16px;
  word-wrap: break-word;
}
