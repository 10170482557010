.searchWrapper {
  position: sticky;
  top: -10px;
  background-color: #ffffff;
  z-index: 1;
  display: flex;
  grid-gap: 10px;
  margin-bottom: 16px;
}

.search {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid var(--button-background-color);
  transition: all 0.4s ease;
}

.search:hover {
  background-color: var(--selected-background-color);
}

.searchActive {
  box-shadow: var(--button-background-color) 0px 2px 8px 0px;
  background-color: var(--selected-background-color);
}

.searchIcon {
  margin-right: 14px;
  color: var(--main-grey-color);
}

.searchInputWrapper {
  width: 100%;
  height: 100%;
}

.searchInput {
  height: 100%;
  padding: 0;
}

.closeIcon {
  display: block;
  color: var(--main-grey-color);
  cursor: pointer;
}
