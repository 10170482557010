.wrapper {
  background: var(--main-background-color);
  padding: 27px 0 0 0;
  max-width: 728px;
}

.divider {
  width: 100%;
  height: 1px;
  background: var(--button-background-color);
}