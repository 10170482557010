.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transform: rotate(-90deg);
}

.input-wrapper {
  margin-bottom: 32px;
}

.radio-wrapper {
  display: flex;
  flex-direction: column;
}

.radio + .radio {
  margin-top: 10px;
}

.title {
  margin-bottom: 32px;
}

.subtitle {
  margin-bottom: 24px;
}

.button-back {
  padding: 0;
  margin-bottom: 32px;
}

.wrapper {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 118px);
  display: flex;
  place-content: center;
}

.content {
  padding: 32px 0 0 32px;
}

.wrapper .button-back:hover {
  background: none;
}

.input-root::before,
.input-root::after {
  display: none;
}

.field-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.input-subtitle {
  margin-bottom: 32px;
  margin-top: 8px;
}

.add-text-field {
  flex-grow: 1;
  margin-right: 30px;
}

.input-title {
  margin-bottom: 8px;
}

.add-button,
.delete-button {
  white-space: nowrap;
  flex-shrink: 0;
  align-self: flex-start;
}

.add-button {
  margin-top: 8px;
}

.asterisk::after {
  content: '*';
  color: red;
}

.add-button-bottom {
  margin-top: 0;
  margin-bottom: 20px;
}

.header-text {
  max-width: 800px;
}

.add-icon {
  margin-right: 8px;
}

.add-button {
  margin-bottom: 24px;
}

.providers {
  margin-bottom: 33px;
}

.profile {
  display: flex;
  align-items: center;
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  padding: 12px 20px 12px 24px;
}

.provider {
  display: flex;
  align-items: center;
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  padding: 12px 20px 12px 24px;
}

.provider + .provider {
  margin-top: 16px;
}

.provider:hover {
  background: var(--background-darkened-color);
}

.icon-button-wrapper {
  padding: 0;
  flex: 0 0 auto;
}

.icon-button:hover path {
  stroke: var(--main-color);
}

.divider {
  width: calc(100% + 64px);
  height: 1px;
  margin-left: -32px;
  margin-bottom: 32px;
  background: var(--button-background-color);
}

.submit-buttons {
  margin-left: auto;
  width: fit-content;
  margin-top: 24px;
}

.create-button {
  margin-left: 16px;
}

.switch-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
}

.token-input {
  position: relative;
}

.token-textfield {
  margin-bottom: 32px;
}

.token-input::after {
  content: 'секунд';
  display: block;
  left: auto;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  right: 32px;
  margin: 0;
  color: #b6bac6;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 21px;
}

.input-error {
  margin-bottom: 8px;
}

.custom-checkbox-wrapper {
  display: flex;
  align-items: flex-start;
}

.custom-checkbox-wrapper + .custom-checkbox-wrapper {
  margin-top: 16px;
}

.provider-name-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
  margin-right: 24px;
}

.provider-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.provider-icon-wrapper {
  border-radius: 8px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: var(--hover-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-right: 24px;
  border: 1px solid var(--button-background-color);
  background-size: cover;
  background-repeat: no-repeat;
}

.save-modal {
  background: var(--main-background-color);
  padding: 24px 24px 32px 32px;
  border-radius: 2px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-tooltip {
  background: var(--main-color);
  color: var(--main-background-color);
}

.input-tooltip-arrow {
  color: var(--main-color);
}

.input-adornment-button:hover {
  background: none !important;
}

.input-adornment-button:hover path {
  stroke: var(--main-color);
}

.info-item {
  display: flex;
  min-height: 64px;
  align-items: center;
  max-height: 128px;
}

.info-item + .info-item {
  border-top: 1px solid var(--button-background-color);
}

.flex-wrap {
  display: flex;
  flex-flow: nowrap;
  width: calc(100% - 80px);
}

.info-item-title {
  width: 250px;
}

.typeButton {
  flex-shrink: 0;
  height: 36.5px;
}

.type-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 32px;
}

.panel {
  padding-left: 32px;
  border: 1px solid var(--button-background-color);
  border-radius: 6px;
  margin-bottom: 32px;
}

.accordion {
  margin-bottom: 32px !important;
  padding: 0;
  box-shadow: none;
}

.accordion::before {
  display: none;
}

.accorion-summary {
  padding: 32px;
}

.accorion-summary-content {
  margin: 0 !important;
}

.accordion-details {
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}

.password-item {
  margin-top: 16px;
  display: flex;
  align-items: center;
  border: 1px solid var(--button-background-color);
  border-radius: 4px;
  padding: 12px 20px 12px 24px;
}

.password-item:hover {
  background: var(--background-darkened-color);
}

.password-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.password-title {
  margin-bottom: 4px;
}

.password-head {
  display: flex;
  flex-direction: column;
}

.license-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.license-title {
  font-size: 14px;
  color: var(--main-color);
  margin-bottom: 4px;
}

.license-head {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1023px) {
  .wrapper {
    padding: 33px 16px 0;
    width: 100%;
  }

  .header-text {
    width: 100%;
  }

  .padding-wrapper {
    padding: 25px 0;
  }

  .title {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  .provider-name-wrapper {
    margin-right: 12px;
  }
}
