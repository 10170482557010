.contentWrapper {
  display: flex;
  width: 100%;
}

.content {
  display: flex;
  width: 100%;
}

.clientMainInfo {
  margin: auto;
  width: 200px;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clientAddInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  margin-right: 20px;
}

.clientDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.clientScopesInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.clientInfo {
  display: flex;
}

.clientTitle {
  display: inline;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.1s ease-in-out;
}

.clientTitle:hover {
  cursor: pointer;
  color: var(--accent-color);
  text-decoration: underline;
}

.scopesList {
  display: flex;
  grid-gap: 10px;
  margin-top: 10px;
}

.scopeIcon {
  width: 20px;
  height: 20px;
}

.scopeButton {
  width: 30px; 
  height: 30px;
  background: var(--selected-background-color);
  border-radius: 12px;
}

.moreButtonWrapper {
  align-content: center;
  margin-left: 20px;
}

.moreButton {
  padding: 8px;
}

.moreButton:hover {
  background: var(--button-background-color);
}

@media screen and (max-width: 1023px) {
  .clientMainInfo {
    width: 160px;
  }

  .clientAddInfo {
    width: 160px;
  }

  .clientDescription {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .scopesList {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {  
  .clientAddInfo {
    display: none;
  }

  .clientDescription {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .clientScopesInfo {
    margin-top: 14px;
  }
}

@media screen and (max-width: 599px) {
  .contentWrapper {
    display: block;
  }

  .content {
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .clientMainInfo {
    width: 100%;
    display: grid;
    max-width: unset;
    margin-right: 40px;
    justify-content: flex-start;
  }

  .clientTitle {
    width: 100%;
  }

  .moreButton {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

@media screen and (max-width: 460px) {
  .moreButton {
    top: 10px;
    right: 10px;
  }
}


